"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default(_ref) {
  var data = _ref.data;
  return {
    "schemaType": "Object",
    "label": "Editor",
    "properties": {
      "props": {
        "schemaType": "Object",
        "properties": {
          "settings": {
            "schemaType": "Object",
            "properties": {
              "props": {
                "schemaType": "Object",
                "properties": {
                  "settings": {
                    "schemaType": "Array",
                    "items": {
                      "schemaType": "Object",
                      "properties": {
                        "title": {
                          "schemaType": "string"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };
}